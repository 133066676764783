<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Consulta de nóminas
            </div>

            <v-row v-if="loading"  justify="center">
                <v-progress-linear class="my-2"
                indeterminate
                rounded
                ></v-progress-linear>
            </v-row>
            <v-row v-if="!encontrado"  justify="center">
                <span class="my-2" style="display: flex">
                    <v-icon>mdi-file-alert</v-icon>
                    Archivo no encontrado
                </span>
            </v-row>
            <v-row v-if="encontrado && !loading" justify="center">
                <v-col cols="12" sm="11" md="10" lg="9" xl="5">
                    <pdf
                        :src="'data:application/pdf;base64,'+ nom" 
                        :page="1" 
                        style="width: 100%; border-style:solid; border-width: 1px; border-color: silver">
                    </pdf>
                </v-col>
            </v-row>
            
            <v-row justify="center">
                    <v-btn
                    to="/nominas-consulta"
                    class="mt-6 mb-4">
                    Salir
                    </v-btn>
                </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import pdf from 'vue-pdf'
export default ({
    components: {
        pdf
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            downloading: false,
            fileName: null,
            encontrado: true,
            nom: null,
            nomName: ''
        }
    },
    watch: {
    },
    mounted() {
        try {
            this.fileName = atob(this.$route.params.file);
            this.loadData();
        }
        catch {
            this.encontrado = false;
            this.loading = false;
        }
    },
    methods: {
      getNominaFromApi(){
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DESCARGA_SALARIO',
            EmpId: this.empId,
            PerId: this.perId,
            FileName: this.fileName,
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                this.encontrado = false;
            }
          });
        })
      },
      loadData(){
        this.getNominaFromApi()
        .then(data => {
          if(data.item === false) { this.encontrado = false; }
          this.nom = data.item.File;
          this.nomName = data.item.FileName
        });
      }
    } 
})
</script>
